<template>
  <div class="bk-page" id="goods">
    <div class="bk-page-scroll">
      <div class="bk-page-lst">
        <Form :label-width="90" @submit.native.prevent class="bk-page-search" inline>
          <FormItem label="委托单">
            <Input :value="searchData1.OrderCode" @on-clear="unPickOrder" clearable readonly>
              <Button @click="toPickOrder" icon="ios-more" slot="append"></Button>
            </Input>
          </FormItem>
          <FormItem label="测试项目">
            <Select clearable v-model="searchData1.ItemId">
              <Option
                :key="`item-${item.Id}`"
                :value="item.Id"
                v-for="item in orderItems"
              >{{ item.Name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="类型">
            <Select clearable v-model="searchData1.SubType">
              <Option value="1">样品</Option>
              <Option value="99">其他物料</Option>
            </Select>
          </FormItem>
          <FormItem label="内部编号">
            <Input clearable v-model="searchData1.GoodsNo" />
          </FormItem>
          <FormItem label="原编号">
            <Input clearable v-model="searchData1.Code" />
          </FormItem>
          <FormItem class="btns" style="width: 220px">
            <Button @click="onSearch" type="primary">搜索</Button>
            <Button @click="reSearchData" type="default">重置</Button>
            <Button @click="toAdd" ghost type="primary" v-if="$able.map.WmsGoodsSave">
              <Icon type="md-add" />新增
            </Button>
          </FormItem>
          <FormItem :label-width="10" v-if="$able.map.WmsStockInAdd">
            <Button @click="toStockIn" type="primary">快速接收入库</Button>
          </FormItem>
        </Form>
        <Table
          :columns="tableCols"
          :data="tableData"
          :loading="tableLoading"
          @on-selection-change="onSelect"
          ref="table"
        ></Table>
        <Page
          :current="pageIndex"
          :page-size="pageSize"
          :total="recordCount"
          @on-change="(index) => onChangePage(index, 0)"
          @on-page-size-change="(size) => onChangePage(1, size)"
          show-elevator
          show-sizer
          show-total
          transfer
        />
      </div>
    </div>
    <pick-order @on-ok="onPickOrder" ref="PickOrder" />
    <div class="fix-print" v-if="tablePicked.length>0">
      <Button :loading="printing" @click="toPrint" icon="md-print" title="打印" type="primary">打印</Button>
    </div>
    <stock-in ref="stockIn" @on-add="initSearch" @on-upd="reqTable"></stock-in>
  </div>
</template>
<script>
import PickOrder from "../../components/PickOrder.vue";
import stockIn from "./stockIn-edit.vue";
export default {
  components: { PickOrder, stockIn },
  data() {
    return {
      orderItems: [],
      searchData1: {},
      searchData2: {},
      // ——————————
      tableLoading: false,
      tableCols: [
        { title: "委托单", key: "OrderCode", width: 150 },
        { title: "测试项目", key: "ItemNames" },
        {
          title: "类型",
          width: 80,
          render: (h, params) => {
            return h(
              "span",
              // this.$designConst.goods.Type[params.row.Type] +
              //   " - " +
              this.$designConst.goods.SubType[params.row.SubType]
            );
          },
        },
        {
          title: "物料编号",
          render: (h, params) => h("span", params.row.GoodsNo || "--"),
          width: 170
        },
        {
          title: "物料原编号",
          render: (h, params) => h("span", params.row.Code || "--"),
        },
        { title: "物料名称", key: "Name" },
        {
          title: "图片",
          render: (h, params) => {
            let a = [];
            if (params.row.Img1)
              a.push(
                h("Img", {
                  attrs: { class: "lst-xs-img", src: params.row.Img1 },
                  on: {
                    click: () => {
                      this.viewImg(params.index, "Img1");
                    },
                  },
                })
              );
            if (params.row.Img2)
              a.push(
                h("Img", {
                  attrs: { class: "lst-xs-img", src: params.row.Img2 },
                  on: {
                    click: () => {
                      this.viewImg(params.index, "Img2");
                    },
                  },
                })
              );

            if (params.row.Img3)
              a.push(
                h("Img", {
                  attrs: { class: "lst-xs-img", src: params.row.Img3 },
                  on: {
                    click: () => {
                      this.viewImg(params.index, "Img3");
                    },
                  },
                })
              );

            if (params.row.Img4)
              a.push(
                h("Img", {
                  attrs: { class: "lst-xs-img", src: params.row.Img4 },
                  on: {
                    click: () => {
                      this.viewImg(params.index, "Img4");
                    },
                  },
                })
              );

            if (params.row.Img5)
              a.push(
                h("Img", {
                  attrs: { class: "lst-xs-img", src: params.row.Img5 },
                  on: {
                    click: () => {
                      this.viewImg(params.index, "Img5");
                    },
                  },
                })
              );
            return a;
          },
        },
        { title: "描述", key: "StockDescription", tooltip: true },
        {
          title: "操作",
          width: 80,
          align: "right",
          render: (h, params) => {
            let a = [];
            a.push(
              h("Icon", {
                props: {
                  type: this.$able.map.WmsGoodsSave ? "md-create" : "md-open",
                  size: 18,
                },
                attrs: { title: this.$able.map.WmsGoodsSave ? "编辑" : "详情" },
                style: { cursor: "pointer", color: "#2d8cf0" },
                on: {
                  click: () => {
                    this.$tab.open(this, "goodsEdit", {
                      go: 1,
                      re: 1,
                      id: params.row.Id,
                    });
                  },
                },
              })
            );
            a.push(h("Divider", { props: { type: "vertical" } }));
            if (this.$able.map.WmsGoodsDel) {
              a.push(
                h("Icon", {
                  props: { type: "md-close", size: 18 },
                  attrs: { title: "删除" },
                  style: { cursor: "pointer", color: "#ed4014" },
                  on: {
                    click: () => {
                      this.toDel(params.row);
                    },
                  },
                })
              );
            }
            return a;
          },
        },
      ],
      tableData: [],
      tablePicked: [],
      printing: false,
      // ——————————
      recordCount: 0,
      pageIndex: 1,
      pageSize: 10,
    };
  },
  activated: function () {
    this.$root.$off("on-refresh");
    this.$root.$on("on-refresh", () => {
      this.init();
    });
    if (this.$route.params.re == 1) {
      this.init();
    }
  },
  methods: {
    init() {
      if (this.$store.state.order.Id) {
        this.searchData1 = {
          OrderId: this.$store.state.order.Id,
          OrderCode: this.$store.state.order.Code,
        };
        this.pageIndex = 1;
        this.onSearch();
      } else {
        this.initSearch();
      }
    },
    initSearch() {
      if (
        this.$able.map.WmsGoodsPrint &&
        this.tableCols[0].type != "selection"
      ) {
        this.tableCols.unshift({
          type: "selection",
          width: 50,
          align: "center",
        });
      } else if (
        !this.$able.map.WmsGoodsPrint &&
        this.tableCols[0].type == "selection"
      ) {
        this.tableCols.splice(0, 1);
      }
      this.searchData1 = {};
      this.pageIndex = 1;
      this.onSearch();
    },
    onSearch() {
      let data = this.$util.copy(this.searchData1);
      this.searchData2 = data;
      this.pageIndex = 1;
      this.reqTable();
    },
    reSearchData() {
      this.searchData1 = {};
    },
    reqTable() {
      if (this.$able.map.WmsGoodsQry == undefined) {
        this.$Message.error("无权限查询");
        return;
      }
      this.$refs.table.selectAll(false);
      let params = Object.assign(
        {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
        },
        this.searchData2
      );
      this.tableLoading = true;
      this.$axios
        .get("/Api/WmsGoods/QueryPg", { params: params })
        .then((res) => {
          this.tableLoading = false;
          if (res.result) {
            this.recordCount = res.data.RecordCount;
            let lst = res.data.DataSet || [];
            for (let a of lst) {
              if (a.SubType != 1) {
                a._disabled = true;
              }
            }
            this.tableData = lst;
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pageIndex = index;
      }
      if (size > 0) {
        this.pageSize = size;
      }
      this.reqTable();
    },
    // ——————————选择
    onSelect(selection, row) {
      console.log("on-select", selection);
      this.tablePicked = selection;
    },
    // ——————————委托单
    toPickOrder() {
      if (this.searchData1.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.searchData1.OrderId, Code: this.searchData1.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      if (lst[0]) {
        this.searchData1.OrderId = lst[0].Id;
        this.$set(this.searchData1, "OrderCode", lst[0].Code);
        this.reqItems();
      } else {
        this.unPickOrder();
      }
    },
    unPickOrder() {
      this.searchData1.OrderId = "";
      this.$set(this.searchData1, "OrderCode", "");
      this.searchData1.ItemId = 0;
      this.orderItems = [];
    },
    // 测试项目
    reqItems() {
      this.$axios
        .get("/Api/BtmsOrderItems/QueryPg", {
          params: {
            PageIndex: 1,
            PageSize: 99,
            OrderId: this.searchData1.OrderId,
          },
        })
        .then((res) => {
          if (res.result) {
            this.orderItems = res.data.DataSet;
          }
        });
    },
    // ==========
    toAdd() {
      this.$tab.open(this, "goodsEdit", { go: 1, re: 1, id: 0 });
    },
    toDel(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除：" + this.$util.color(row.Name),
        onOk: () => {
          this.doDel(row.Id);
        },
      });
    },
    doDel(id) {
      this.$axios
        .post("/Api/WmsGoods/Delete", { Id: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("删除成功");
            this.reqTable();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.$Message.error(err.errormsg);
        });
    },
    toPrint() {
      this.$Modal.confirm({
        title: "注意",
        content: "是否打印",
        onOk: () => {
          this.doPrint();
        },
      });
    },
    doPrint() {
      let ids = this.tablePicked.map((v) => v.Id);
      this.printing = true;
      this.$axios
        .post("/Api/WmsGoods/Print", { Ids: ids })
        .then((res) => {
          this.printing = false;
          if (res.result) {
            this.$Message.success("打印成功");
            this.$refs.table.selectAll(false);
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.printing = false;
          this.$Message.error(err.errormsg);
        });
    },
    toStockIn() {
      this.$refs.stockIn.open("", "fast")
    },
    viewImg(index, imgName) {
      let imgs = [],
        i = 0;
      if (this.tableData[index].Img1) {
        imgs.push(this.tableData[index].Img1);
        if (imgName == "Img1") {
          i = imgs.length - 1;
        }
      }
      if (this.tableData[index].Img2) {
        imgs.push(this.tableData[index].Img2);
        if (imgName == "Img2") {
          i = imgs.length - 1;
        }
      }
      if (this.tableData[index].Img3) {
        imgs.push(this.tableData[index].Img3);
        if (imgName == "Img3") {
          i = imgs.length - 1;
        }
      }
      if (this.tableData[index].Img4) {
        imgs.push(this.tableData[index].Img4);
        if (imgName == "Img4") {
          i = imgs.length - 1;
        }
      }
      if (this.tableData[index].Img5) {
        imgs.push(this.tableData[index].Img5);
        if (imgName == "Img5") {
          i = imgs.length - 1;
        }
      }
      this.$root.$emit("view-imgs", imgs, i);
    },
  },
};
</script>
<style lang="less">
#goods {
  .lst-xs-img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 4px;
    margin-right: 2px;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 0 2px rgba(45, 140, 240, 0.2);
    }
  }
  .lst-xs-img::last-child {
    margin-right: 0;
  }
  .fix-print {
    position: fixed;
    top: 200px;
    left: 40%;
    padding: 30px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
}
</style>

